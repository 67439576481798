/* Header */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* header END */



@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700;800&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');






@import url('https://fonts.googleapis.com/css2?family=DynaPuff:wght@400;500;600;700&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  /* margin: 1rem 2rem; */
  font-family: 'Open Sans', sans-serif;

}

.main-section-header{
  background: #003b54e6;
  overflow: hidden;
  padding: 1rem 0;
  position: fixed;
  z-index: 99999;
  /* top: 0px; */
  width: 100%;
}


.main-section{
  background: #fff;
  overflow: hidden;
  padding:2rem 0 ;
}

.main-container{
  width: 90%;
  margin:0 auto;

}



.header-contact{
  
}





::-webkit-scrollbar {
  width: 0.6rem;
  background-color: #006a8f;
}
::-webkit-scrollbar-thumb {
  background-color: #d0a133;
}


.swiper-fixed-width-100 img{
  width: 100%;
}

@media only screen and (max-width: 600px) {

  .main-section-header {
    position: inherit!important;
  }
}

  
